<template>
  <div class="productContent">
    <div class="container">
      <div class="nav">
        <div class="auto-container">
          <h1>{{content.title}}</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            /> <a href="/productList">产品列表</a> 
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />{{content.title}}
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <!-- 标题 -->
          <!-- <h2 class="title">{{content.title}}</h2> -->
          <!-- 正文 -->
         <div v-html="content.contents"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supportContant from "./index.js";
export default supportContant;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>