import axios from "axios"
export default {
    data() {
        return {
            weburl: "https://www.rayscloud.cn/webapi",
            content:{},
            menu:{},
        }
    },
    methods: {
        getList(code) {
            axios.get(`${this.weburl}/api/Cont/${code}`)
                .then((res) => {
                    if (res.status == 200) {
                        this.content = res.data.data;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    created(){
        if(localStorage.getItem("secondMenu")){
            this.menu=JSON.parse(localStorage.getItem("secondMenu"));
        }
        if(this.$route.query.code){
            this.getList(this.$route.query.code);
        }
    },
}